import './index.css'
import scrollToSection from '../../functions/scrollToSection'
const Header = () => {


  return (
    
      <header className="header">
        <div id="navbar">
          <div className="logo logosvg"></div>
          <div className='navb'>
            <button className="btn-txt" onClick={() => scrollToSection('section1')}>Accueil</button>
            <button className="btn-txt" onClick={() => scrollToSection('section2')}>Services</button>
            <button className="btn-txt" onClick={() => scrollToSection('section4')}>À Propos</button>
            <button className="btn-txt" onClick={() => scrollToSection('section6')}>Contact</button>
          </div>
        </div>

      </header>
  )
}

export default Header