import React from 'react'
import './index.css'
const Section7 = () => {
  return (
    <div id="section7" style={{ backgroundColor: '#FFF' }}>
    <div className="copyrightsvg"></div>
    <div id="footerc">
      <div id="icons">
        <div className="callsvg" ></div>
        <div className="smssvg" > </div>
        <div className="locationsvg" > </div>
      </div>
      <div id="contactf" >
        <h1 id="phone" className="contactf">+33623527987</h1>
        <h1 id="mail" className="contactf">digitalsolutionsinformatique@gmail.com</h1>
        <h1 id="location" className="contactf">18-130 avenue Jean Jaurès 75171 Paris CEDEX 19</h1>
      </div>
    </div>
  </div>
  )
}

export default Section7