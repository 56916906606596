import React from 'react'
import './index.css'

const Section2 = () => {
  return (
    <div id="section2" className="section" style={{ backgroundColor: '#00060C' }}>
    <div className="nosservices"> </div>
    <h1 className="headers2">Conseils en systèmes d'information et transformation digitale</h1>
    <p className="pargraphs2">Expertise en SI et transformation digitale pour des conseils stratégiques et efficaces.</p>
    <div className="man1png"> </div>
  </div>
  )
}

export default Section2