import React from 'react'
import './index.css'
const Section4 = () => {
  return (
    <div id="section4" className="section" >
    <div className="section-a-props" >
        <div id="rectangle">
          <h1 id="header1">Votre satisfaction est notre objectif</h1>
          <h1 id="header2">+20 clients satisfaits</h1>
        </div>
        <div id="apropos">
          <h1 id="header6"> À propos  <span className="colored-text">de nous</span></h1>
          <p id="aproposp">Notre équipe d'experts passionnés vous propose une gamme complète de services informatiques. De la conception et du développement de solutions sur mesure à la consultation stratégique en systèmes d'information et transformation digitale, nous sommes là pour répondre à tous vos besoins IT.</p>
        </div>
      </div>
    <div className="man2png"></div>
  </div>
  )
}

export default Section4