
import './index.css'

const Section3 = () => {
  return (
    <div id="section3" className="section" style={{ backgroundColor: '#FDFDFD' }}>
        <h1 className="headers3">Développement des solutions informatiques</h1>
        <p className="pargraphs3">Nous concevons et développons des solutions informatiques sur mesure pour répondre à vos besoins spécifiques.</p>
        <div id="cards">
          <div className="box"  >
            <h2 className="cardh">Développement de logiciels</h2>
            <p className="cardp">Développement de logiciels et d'applications web sur mesure pour une expérience utilisateur optimale.</p>
          </div>
          <div className="box"  >
            <h2 className="cardh">marketing</h2>
            <p className="cardp">Stratégies de marketing innovantes pour propulser votre entreprise vers de nouveaux horizons.</p>
          </div>
          <div className="box"  >
            <h2 className="cardh">Conception visuelle</h2>
            <p className="cardp">Conception et création de visuels percutants pour donner vie à votre vision artistique.</p>
          </div>
        </div>

      </div>
  )
}

export default Section3