import React from 'react'
import ContactForm from '../ContactForm'
import './index.css'
const Section6 = () => {
  return (
    <div id="section6" className="section" style={{ backgroundColor: '#a5a5a5' }}>
        <h1 className='Header6'>Essayez nos services
          maintenant</h1>
        <div><ContactForm /></div>
      </div>
  )
}

export default Section6