import React, { useState } from 'react';
import './index.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

 const handleSubmit = async (e) => {
  e.preventDefault();
  console.log({ formData });
  const response = await fetch("https://dsi.vercel.app/send", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({ formData }),
  })
    .then((res) => res.json())
    .then(async (res) => {
      const resData = await res;
      console.log(resData);
      if (resData.status === "success") {
        alert("Message Sent");
      } else if (resData.status === "fail") {
        alert("Message failed to send");
      }
    })
    .then(() => {
      setFormData({
        name: '',
        email: '',
        message: '',
      });
    });
};
/*
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      
      console.log(formData.name);
      // Display success message to the user
    } catch (error) {
      console.error('Error sending email:', error); 
      // Display error message to the user
    }
  };
*/
  return (

    <div>
      <form  onSubmit={handleSubmit.bind(this)}>
        <div className='input-data'>
          <div className="field-groupe">
            <input className="input-field"
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              placeholder='Nom et Prénom'
            />
            <label htmlFor='name' className='input-label'> Nom et Prénom </label>
          </div>
          <div className="field-groupe">
            <input className="input-field"
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              placeholder='Adresse E-mail'
            />
            <label className="input-label">Adresse E-mail</label>
          </div>
          <div>
            <textarea className='contactbox'
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              placeholder='Détails du projet'
            />
          </div>
          <button className="Envoyer" type="submit">Envoyer</button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
