import './index.css'
import scrollToSection from '../../functions/scrollToSection'

const Section1 = () => {
  return (
    <div id="section1" className="section" style={{ backgroundColor: '#f5f5f5' }}>
    <div className="logo"></div>
    <h1 className="main-txt">Élevez votre transformation
      numérique</h1>
    <p className='Homepargraph'>Vivez le futur avec Digital Solutions Informatiques Déchaînement de l'excellence technologique.</p>
    <button className="Discoverb" onClick={() => scrollToSection('section4')}>Découvrir</button>
  </div>
  )
}

export default Section1