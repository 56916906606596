import Header from "./components/Header";
import Section1 from "./components/Section 1";
import Section2 from "./components/Section 2";
import Section3 from "./components/Section 3";
import Section4 from "./components/Section 4";
import Section6 from "./components/Section 6";
import Section7 from "./components/Section 7";
import './app.css'

const App = () => {
  return (
    <div>
      <Header />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section6 />
      <Section7 />
    </div>
  )
}

export default App;